<template>
  <div class="vm-card-box">
    <div class="card vm-card">
      <div class="card-body">
        <div class="row">
          <!--  -->
          <div class="col-md-6">
            <div class="row col">
              <img
                src="@assets/img/logo-monster-mac.svg"
                alt="login-background"
                class="bg-login-monster"
                width="150px"
              />
            </div>
            <div class="row col bg-login-container">
              <img
                src="@assets/img/bg-login.png"
                alt="login-background"
                class="bg-login"
                width="100%"
                height="100%"
              />
            </div>
          </div>

          <!-- Form -->
          <div class="col-md-6 login-form-container">
            <div class="login-back-container" v-if="backToLogin">
              <router-link :to="{ name: 'login' }"
                ><i class="fas fa-arrow-left mr-3"></i>Kembali ke
                Login</router-link
              >
            </div>
            <div class="login-logo-container" v-else>
              <img src="@assets/img/logo-vending.svg" alt="logo-vending" />
            </div>
            <div class="mx-3">
              <slot></slot>
            </div>
            <div class="login-powered-container">
              <div class="text">Powered By</div>
              <div class="image">
                <img
                  src="@assets/img/logo-monster-code.svg"
                  alt="logo-monster-code"
                />
              </div>
            </div>
            <div class="login-version-container">Version {{ app_version }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@assets/scss/auth.scss";
const pjson = require("../../../../package.json");
export default {
  data: () => ({
    app_version: pjson.version,
  }),
  props: {
    backToLogin: Boolean,
  },
  mounted() {
    if (Api.getToken()) {
      this.$router.replace({ name: "Dashboard" });
    }
    $("body").attr("class", "hold-transition vm-login-page text-sm");
    $("body,html").removeAttr("style");
    new adminlte.Layout("body", {});
  },
};
</script>
